/**
 * @file It contains scripts for verify email page.
 */
import { mapGetters, mapActions } from "vuex"
import ThemisPopOver from "@/components/shared/pop-over"

export default {
  name      : "VerifyEmail",
  components: { ThemisPopOver },
  data() {
    return {
      isEmailFormDialogVisible            : false,
      email                               : undefined,
      verificationCode                    : undefined,
      showVerificationCodePopOverForMobile: false
    }
  },
  computed: {
    ...mapGetters({
      isSubscribedForNotification : "auth/isSubscribedForNotification",
      isSubscribingForNotification: "auth/isSubscribingForNotification",
      isVerifyingEmail            : "auth/isVerifyingEmail",
      isEmailVerified             : "auth/isEmailVerified"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getClassForVerifyButton() {
      return !this.isMobileView ? ["mb-10", "mt-8"] : []
    }
  },
  methods: {
    ...mapActions({
      subscribeForNotification: "auth/subscribeForNotification",
      verifyEmail             : "auth/verifyEmail"
    }),
    handleClose() {
      this.isEmailFormDialogVisible = false
      this.email                    = undefined
    }
  },
  watch: {
    isSubscribedForNotification: {
      handler: function(newValue) {
        if (newValue) {
          this.handleClose()
        }
      }
    },
    isEmailVerified: {
      handler: function(newValue) {
        if (newValue) {
          this.$router.push({
            name  : "email-verified",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            }
          })
        }
      }
    }
  }
}